import { Link } from "react-router-dom";
import { TableDataType } from "../../types/order-sheet";
import { formatPrice } from "../../utils/formatPrice";
import { formatDiscount } from "../../utils/formatDiscount";
import "./Footer.scss";
import { CONVERSION_FREE_CURRENCIES } from "../../constants/s3";
import { SAMPLE_UNIT_PRICE } from "../../constants";
import {useCompany} from "../../context/CompanyContext";
import {HintRenderer} from "./Hints";

const Footer = ({
                    orderSheetTableData,
                    currency,
                    exchangeRateFactor,
                    baseCurrency,
                    discount,
                    hints
                }: {
    orderSheetTableData: TableDataType,
    currency: string,
    exchangeRateFactor: number,
    baseCurrency: string,
    discount: number | null,
    hints: HintRenderer
}) => {
  const totalWithoutDiscount = orderSheetTableData.data.reduce((sum, product) => sum + product.calculatedPriceWithoutDiscountApplied, 0)
  const currrency : string = CONVERSION_FREE_CURRENCIES.includes(currency) ? currency : baseCurrency
  const {selectedCompany} = useCompany();

  const totalWithDiscount = orderSheetTableData.data.reduce((sum, product) => sum + (product.calculatedPriceWithPotentialDiscountApplied ?? product.calculatedPriceWithoutDiscountApplied), 0)

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer__price">
          <table>
            <tbody>
            <tr className="footer__price__total">
              <td>Total:</td>
              <td className="footer__total_amt">{`${formatPrice(totalWithDiscount)}`}</td>
              <td className="footer__total_currency">{currrency}</td>
            </tr>
            {discount &&
                <>
                  <tr className="footer__price__discount">
                    <td>Included Discount{hints.renderHintRef("discountHint")}:</td>
                    <td className="footer__discount_amt">{`${formatPrice(totalWithoutDiscount - totalWithDiscount)}`}</td>
                    <td className="footer__discount_currency">{currrency}</td>
                  </tr>
                </>
            }
            </tbody>
          </table>
        </div>
        <div className="footer__left">
            {hints.renderHint((position, text) => (
                <div className="footer__notes">
                    <span><sup>{position}</sup> {text}</span>
                </div>
            ))}
            <div className="footer__links">
            <Link to="/o/zones">Zone List</Link> –{" "}
            <Link to="/o/contact">Contact</Link> –{" "}
            <Link to="/o/privacy">Privacy</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
